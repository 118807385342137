<template>
  <v-app style="background-color:#F6F7FF;">

       <v-navigation-drawer   v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" v-if="logeado==true" app>
        <v-list dense v-if="logeado==true">
          <template v-for="item in items">
            <v-row  v-if="item.heading" :key="item.heading"  align="center">
              <v-col cols="6">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
             <v-col cols="6" class="text-center">
                <a href="#!" class="body-2 black--text">EDIT</a>
              </v-col>
            </v-row>


        

            <!--opcion de unica fila de menu    esta, esta  funcionando-->
            <!-- aqui se van a ver todas las opciones excepto las de administracion -->
            <v-list-item v-if="((item.protegido==false || esAdminstrador==true))" :key="item.text"  :to="{name:item.to}"  link>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          

          </template>
        </v-list>
      </v-navigation-drawer>
  



      <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3"  dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
          <span class="hidden-sm-and-down">Passenger QR</span>
        </v-toolbar-title>
        
        <v-spacer></v-spacer>



        <v-chip class="ma-2 mr-4" color="opcion7">
         
         <v-icon left>
          mdi-account
        </v-icon>
        <span class="text-capitalize" v-if="this.$store.state.usuario  && logeado==true"> {{  this.$store.state.usuario.usr }} </span>
      </v-chip>



        <v-btn icon large  v-if="logeado==true" @click="salir()">
         <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
        <v-btn v-else :to="{name:'login'}" color="success">
          <v-icon>mdi-login</v-icon>
        </v-btn>
      </v-app-bar>


      <v-main>

         <v-container style="width:100%">
           <v-slide-y-transition mode="out-in">
             <router-view/>
        </v-slide-y-transition>


         <v-dialog
                   v-model="loading.estado"
                    hide-overlay
                     persistent
                     width="300">
                        <v-card
                          color="info"
                          dark
                        >
                          <v-card-text>
                          {{loading.titulo}}
                            <v-progress-linear
                              indeterminate
                              color="white"
                              class="mb-0"
                            ></v-progress-linear>
                          </v-card-text>
                        </v-card>
                </v-dialog>


         </v-container>
      </v-main>
      
  </v-app>
</template>

<script>


import { mapState,mapMutations } from "vuex";

export default {
  name: 'App',

  components: {
   
  },

  data: () => ({

   
    
    dialog: false,
    drawer: null,
    arrayDrawer:[],
    items: [

       { icon: 'mdi-home', text: 'Inicio', to:'home', protegido:false  },
       { icon: 'mdi-bus-multiple', text: 'Flotas', to:'flotas', protegido:false  },
       { icon: 'mdi-bus-side', text: 'Vehiculos', to:'vehiculos', protegido:false  },
       { icon: 'mdi-account-injury-outline', text: 'Conductores', to:'conductores', protegido:false  },
       { icon: 'mdi-seat-passenger', text: 'Pasajeros', to:'pasajeros', protegido:false  },
       { icon: 'mdi-highway', text: 'Rutas', to:'rutas', protegido:false  },
       { icon: 'mdi-cog', text: ' Configuracion', to:'configuracion', protegido:true } //solo admin va verlo
    
    ],
  }),
  computed:{
      ...mapState(['loading']),
      
      logeado(){

        let token_storage=localStorage.getItem('token');

        if(token_storage){
          console.log('se detecto cambio en storage:'+this.$store.state.usuario);
          return true;
        }else{

           console.log('se detecto cambio en storage:'+this.$store.state.usuario+' --->');
          return false;
        }
       
         // return this.$store.state.usuario;
      }, 
      
      esAdminstrador(){
          return this.$store.state.usuario && this.$store.state.usuario.rol=='ADMIN';
      }, 
      
      esUsuarioComun(){
          return this.$store.state.usuario && this.$store.state.usuario.rol=='USER';
      }

  },
  created(){


   //this.$store.dispatch("autoLogin");
  
  },


  methods:{
      ...mapMutations(['mostrarLoading','ocultarLoading']),

      salir(){
          this.drawer=false;
          this.$store.state.usuario=null;
          this.$store.dispatch("salir");
         
      }

  }
};
</script>

